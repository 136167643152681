
import { Vue, Component, Prop } from "vue-property-decorator";
import SelectEntity from "../core/SelectEntity.vue";
import CompanyService from "@/services/company-service";
import Company from "../../types/company";

@Component({
  components: {
    SelectEntity
  }
})
export default class SelectCompany extends Vue {
  @Prop({ type: String, default: "Empresa" }) label!: string;
  @Prop({ type: Function, required: true }) onItemSelected!: (
    item: any
  ) => Promise<void>;
  @Prop({ type: Function, required: false }) filterFunction!: (
    item: any
  ) => boolean;
  @Prop({ type: Number, required: false }) initialSelectedId!: number | null;
  @Prop({ type: Object, default: () => ({}) }) autocompleteProps!: Record<
    string,
    any
  >;

  service = CompanyService.getInstance();

  async fetchCompanies({
    page,
    limit,
    search
  }: {
    page: number;
    limit: number;
    search?: string;
  }): Promise<{ items: Array<{ id: number; name: string }>; total: number }> {
    const [error, data] = await this.service.listCompanies({
      page,
      limit,
      sort: "name:ASC",
      search
    });

    if (error) {
      throw error;
    }

    return {
      items: data.items.map((item: Company) => ({
        id: item.id,
        name: item.name,
        cnpj: item.cnpj
      })),
      total: data.total
    };
  }

  async fetchCompanyById(id: number): Promise<{ id: number; name: string }> {
    const [error, company] = await this.service.getCompany(id);

    if (error || !company) {
      return null;
    }

    return { id: company.id, name: company.name };
  }
}
